import React, {useState} from 'react';
import "./App.css"
import LazyLoad from 'react-lazy-load';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
const Video = ({url, label}) => {
    const [checked, setChecked] = useState(
        localStorage.getItem(url) === 'true'
    );
    const [text, setText] = useState(localStorage.getItem(`${url}-text`) || '');

    const handleCheck = () => {
        setChecked(!checked);
        localStorage.setItem(url, !checked);
    };
    const handleText = (event) => {
        setText(event.target.value);
        localStorage.setItem(`${url}-text`, event.target.value);
    };

    return (
        <div>
                <input type="checkbox" id={url} checked={checked} onChange={handleCheck}/>
                <label htmlFor={url}>{label}</label>
                <LazyLoad className="video-wrapper">
                <iframe
                    key={url}
                    src={url}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in- picture"
                    allowFullScreen
                />
            </LazyLoad>
            <textarea placeholder={"Write your essay here"} value={text} onChange={handleText}/>
        </div>
    );
};

const VideoList = ({videos}) => {
    return (
        <div>
            {videos.map((video) => (
                <Video key={video.url} url={video.url} label={video.label}/>
            ))}
        </div>
    );
};

const artVideos = [
    {url: "https://www.youtube.com/embed/T9JvUDrrXmY", label: "Mona Lisa"},
    {url: "https://www.youtube.com/embed/qJLH7JAsBHA", label: "Picasso’s Guernica"},
    {url: "https://www.youtube.com/embed/-e16DmKH01s", label: "Michelangelo's David"},
    {url: "https://www.youtube.com/embed/yUq9qMm9NtI", label: "The Raft of the Medusa by Théodore Géricault"},
    {url: "https://www.youtube.com/embed/rxKR2cHmlPY", label: " Frida Kahlo's The Two Fridas"},
    {url: "https://www.youtube.com/embed/T15Kv6dtYO0", label: "The Arnolfini Portrait by Jan Van Eyck"},
    {url: "https://www.youtube.com/embed/bu9Bm8aw_lI", label: "Andy Warhol's Marilyn"},
    {url: "https://www.youtube.com/embed/fd-Me3EBGYY", label: "Monet's Water Lilies"},
    {url: "https://www.youtube.com/embed/fsz6bkkIHzQ", label: "Mark Rothko's Seagram Murals"},
    {url: "https://www.youtube.com/embed/Z4AEXRtDEEY", label: "The Thinker by Rodin"},
    {url: "https://www.youtube.com/embed/lHePKNTRmdI", label: "Jean-Michel Basquiat's Untitled (Skull)"},
    {url: "https://www.youtube.com/embed/wDjq2gPGIHk", label: "Caravaggio's Taking of Christ"},
    {
        url: "https://www.youtube.com/embed/wFrztzzohJ8",
        label: "Hieronymus Bosch, The Garden of Earthly Delights (Part One)"
    },
    {
        url: "https://www.youtube.com/embed/IYFtwTo50Gg",
        label: "Hieronymus Bosch, The Garden of Earthly Delights (Part Two)"
    },
    {
        url: "https://www.youtube.com/embed/1pvxmqAKIDU",
        label: "Hieronymus Bosch, The Garden of Earthly Delights (Part Three)"
    },
    {url: "https://www.youtube.com/embed/IBcB_dYtGUg", label: "The Great Wave by Hokusai"},
    {url: "https://www.youtube.com/embed/wk9L1N9bRRE", label: "Vincent Van Gogh's The Starry Night"},
    {url: "https://www.youtube.com/embed/lKIbT-4UFaE", label: "Nighthawks by Edward Hopper"},
    {url: "https://www.youtube.com/embed/LQv3YqHisZc", label: "Spiral Jetty by Robert Smithson"},
    {url: "https://www.youtube.com/embed/SNhmSdLDmto", label: "The Kiss by Gustav Klimt"},
    {url: "https://www.youtube.com/embed/t6vzJ6ceJqs", label: "Great Art Cities Explained  London"},
    {url: "https://www.youtube.com/embed/gA0RAPh2ZgU", label: "Salvador Dali's 'The Persistence of Memory'"},
    {url: "https://www.youtube.com/embed/ACnM_063tsw", label: "Great Art Cities Explained  Paris"},
    {url: "https://www.youtube.com/embed/DtaVAml0WcQ", label: "The Birth of Venus by Botticelli"},
    {url: "https://www.youtube.com/embed/wgSBtXNJjhs", label: "Yayoi Kusama"},
    {url: "https://www.youtube.com/embed/f8OIYzV5Npo", label: "Venice Special (Biennale)"},
    {url: "https://www.youtube.com/embed/d50FbuyswAU", label: "The Scream"},
    {url: "https://www.youtube.com/embed/z2hgtFBBbxk", label: "The Milkmaid by Johannes Vermeer"},
    {url: "https://www.youtube.com/embed/8liuFWolR88", label: "New York"},
    {url: "https://www.youtube.com/embed/mQVC0qJGJ_c", label: "Edward Hopper and Cinema Extra "},
    {url: "https://www.youtube.com/embed/9JqS6HfsYIM", label: "The Black Paintings by Goya (Part One)"},
    {url: "https://www.youtube.com/embed/GZc0P3Apfx4", label: "Three Michelangelos in Florence  Great Art Cities"}
];

const scienceVideos = [
    {url: "https://www.youtube.com/embed/EtW2rrLHs08", label: "Climate Change 101 with Bill Nye"},
    {url: "https://www.youtube.com/embed/DkZ7BJQupVA", label: "Climate change by Vox"},
    {url: "https://www.youtube.com/embed/mFP_AjJeP-M", label: "The Insane Biology of: The Octopus"},
    {url: "https://www.youtube.com/embed/7SWvDHvWXok", label: "Questions No One Knows the Answers to (Full Version)"},
    {url: "https://www.youtube.com/embed/91Qo6wzU7F8", label: "Do Monkeys Have a Moral Compass?"},
    {url: "https://www.youtube.com/embed/jhRuUoTnA6g?list=PLJicmE8fK0Ei3dAErHrPBfzRpmFDr4vsy", label: "Why are earthquakes so hard to predict? - Jean-Baptiste P. Koehl"},
    {url: "https://www.youtube.com/embed/iJi61NAIsjs", label: "The Insane Biology of: The Dragonfly"},
    { url: "https://www.youtube.com/embed/-m0YaE8uKcg?list=PLJicmE8fK0EgeNOExG1BAMzk5im7NriG9", label: "The smallest solution to one of our biggest problems - Tierney Thys & Christian Sardet"},
    {url: "https://www.youtube.com/embed/mqHVRgCkCDE", label: "The deadly irony of gunpowder - Eric Rosado"},
    {url: "https://www.youtube.com/embed/gx2l5cu5NmM", label: "The Problem Dogs Solved That We Couldn't"},
    {url: "https://www.youtube.com/embed/sI8NsYIyQ2A", label: "Why do cats act so weird? - Tony Buffington"},
    {url: "https://www.youtube.com/embed/Al-30Z-aH8M", label: "The treadmill's dark and twisted past - Conor Heffernan"    },
    {url: "https://www.youtube.com/embed/hRI0ymx_6aw", label: "The material that could change the world... for a third time"    },
    {url: "https://www.youtube.com/embed/vP8-5Bhd2ag", label: "What happens when your DNA is damaged? - Monica Menesini" },
    {url: "https://www.youtube.com/embed/arJLy3hX1E8", label: "The psychology of narcissism - W. Keith Campbell"},
    {url: "https://www.youtube.com/embed/quOdF1CAPXs", label: "Where do superstitions come from? - Stuart Vyse"},
    {url: "https://www.youtube.com/embed/uXaP43Zbz7U", label: "Why doesn’t anything stick to Teflon? - Ashwini Bharathula"    },
    {url: "https://www.youtube.com/embed/YeB-1F-UKO0", label: "A brief history of chess - Alex Gendler"},
    {url: "https://www.youtube.com/embed/9iMGFqMmUFs", label: "What would happen if you didn’t drink water? - Mia Nacamulli" },
    {url: "https://www.youtube.com/embed/xyQY8a-ng6g", label: "How the food you eat affects your brain - Mia Nacamulli"},
    {url: "https://www.youtube.com/embed/lEXBxijQREo", label: "How sugar affects the brain - Nicole Avena"},
    {url: "https://www.youtube.com/embed/MMmOLN5zBLY", label: "The benefits of a bilingual brain - Mia Nacamulli"},
    {url: "https://www.youtube.com/embed/YV8TT9LRBrY?list=PLJicmE8fK0EixypMdb334L4cB_2vv2kXg", label: "Is fire a solid, a liquid, or a gas? - Elizabeth Cox" },
    {url: "https://www.youtube.com/embed/9HiADisBfQ0", label: "The Secret Language of Trees"},
    {url: "https://www.youtube.com/embed/BTRUqdH8IqQ", label: "The Insane Biology of: Sloths"},
    {url: "https://www.youtube.com/embed/NZaZAH2WHAY", label: "The Insane Evolution of: Flight"},
    {url: "https://www.youtube.com/embed/DmanbWwMa5w", label: "The Truth About Lab-Grown Meat"},
    {url: "https://www.youtube.com/embed/uRhkDN2WjzI", label: "How aspirin was discovered - Krishna Sudhir"},
    {url: "https://www.youtube.com/embed/qNS2jj2w-GI", label: "How the world's longest underwater tunnel was built - Alex Gendler"},
    {url: "https://www.youtube.com/embed/lSXNqsOoURg", label: "Why elephants never forget - Alex Gendler"},
    {url: "https://www.youtube.com/embed/_r4c2NT4naQ", label: "The science of skin color - Angela Koine Flynn"},
    {url: "https://www.youtube.com/embed/-sEpBGF106k", label: "Why don’t poisonous animals poison themselves? - Rebecca D. Tarvin"},
    {url: "https://www.youtube.com/embed/Pu_ijC8HFRU", label: "How does a jellyfish sting? - Neosha S Kashef"},
    {url: "https://www.youtube.com/embed/QKae1k1BDdA", label: "A brie(f) history of cheese - Paul Kindstedt"},
    {url: "https://www.youtube.com/embed/TGLYcYCm2FM", label: "Why are some people left-handed? - Daniel M. Abrams"},
    {url: "https://www.youtube.com/embed/gKfjHTk8KrY", label: "History’s deadliest colors - J. V. Maranto"},
    {url: "https://www.youtube.com/embed/qD0_yWgifDM", label: "The science of spiciness - Rose Eveleth"},
    {url: "https://www.youtube.com/embed/gedoSfZvBgE", label: "The benefits of a good night's sleep - Shai Marcu"},
    {url: "https://www.youtube.com/embed/ibjUpk9Iagk", label: "The history of chocolate - Deanna Pucciarelli"},
    {url: "https://www.youtube.com/embed/4dEf1ep3O9I", label: "The mysterious life and death of Rasputin - Eden Girma"},
    {url: "https://www.youtube.com/embed/TbvWnJh9e-g", label: "Why Spider Silk is Stronger Than Steel"},
    {url: "https://www.youtube.com/embed/VLBDVXLiWxQ", label: "The Insane Biology of: Ant Colonies"}
];

const philosophyVideos = [
    {url: "https://www.youtube.com/embed/jLesc5lITvo", label: "Plato’s best (and worst) ideas - Wisecrack"},
    {url: "https://www.youtube.com/embed/R9OCA6UFE-0", label: "The philosophy of Stoicism - Massimo Pigliucci"},
    {url: "https://www.youtube.com/embed/LxZkdQfgot8", label: "How one person saved over 2,000 children from the Nazis - Iseult Gillespie"}
];
const handleClick = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    });
};

const App = () => {
    return (
        <div>
            <h1>Danielle's Courses</h1>
            <p className="menu">
                <span className="space"><a href="#art">Art History</a></span>
                <span className="space"><a href="#science">Science</a></span>
                <span className="space"><a href="#philosophy">Philosophy</a></span>
                <span className="space">Literature</span>
                <span className="space">History</span></p>


            <h2 id="art">Art History</h2>
            <VideoList videos={artVideos}/>
            <h2 id="science">Science</h2>
            <VideoList videos={scienceVideos}/>
            <h2 id="philosophy">Philosophy</h2>
            <VideoList videos={philosophyVideos}/>
            <h2 id="science"></h2>

            <div className="scroll-top" onClick={handleClick}>
                <FontAwesomeIcon icon={faArrowUp} />
            </div>
        </div>
    );
};

export default App;
